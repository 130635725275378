const toolbars = [
  "filestack",
  // "wproofreader",
  "undo",
  "redo",
  "|",
  // "copyButton",
  // "cutButton",
  // "pasteButton",
  // "heading",
  "|",
  "fontSize",
  // "fontFamily",
  "|",
  "fontColor",
  "fontBackgroundColor",
  // "highlight",
  "|",
  "bold",
  "italic",
  "underline",
  "strikethrough",
  "|",
  "alignment",
  "lineSpacing",
  "|",
  "numberedList",
  "bulletedList",
  "|",
  "outdent",
  "indent",
  "|",
  //"todoList",
  "link",
  "blockQuote",
  // "pageBreak",
  "insertTable",
  "|",
  //"superscript",
  //"subscript",
  "specialCharacters",
  // "|",
  //"codeBlock",
  //"code",
  "horizontalLine",
  "|",
  // "defaultfont",
  "removeFormat",
  "webSpeech",
];

const fonts = [
  "Sans-serif",
  "Serif",
  "Courier",
  "Arsenal",
  "Architects Daughter",
  "Cinzel",
  "Josefin Sans",
  "Lato",
  "Libre Franklin",
  "Merriweather",
  "Montserrat",
  "Playfair Display",
  "Poppins",
  "Roboto",
  "Rubik",
  "Shadows Into Light",
  "Source Serif Pro",
  "Ubuntu",
  "Zilla Slab",
];

const fontSizes = [
  "Default (14px)",
  9,
  10,
  11,
  12,
  14,
  16,
  18,
  20,
  22,
  24,
  26,
  28,
  30,
  32,
  34,
  36,
  38,
  40,
  42,
  44,
  46,
  48,
  50,
  52,
  54,
  56,
  58,
  60,
  62,
  64,
  66,
  68,
  70,
  72,
  74,
  76,
  78,
  80,
  82,
  84,
  86,
  88,
  90,
  92,
  94,
  96,
  98,
  100,
];

const fontColors = [
  {
    color: "#ffffff",
    label: "White",
    hasBorder: true,
  },
  {
    color: "#000000",
    label: "Black",
    hasBorder: true,
  },
  {
    color: "#bc3a41",
    label: "#bc3a41",
    hasBorder: true,
  },
  {
    color: "#4b2012",
    label: "#4b2012",
    hasBorder: true,
  },
  {
    color: "#1e334c",
    label: "#1e334c",
    hasBorder: true,
  },
  {
    color: "#9a4e54",
    label: "#9a4e54",
    hasBorder: true,
  },
  {
    color: "#38b6ff",
    label: "#38b6ff",
    hasBorder: true,
  },
  {
    color: "#395aa0",
    label: "#395aa0",
    hasBorder: true,
  },
  {
    color: "#c3b371",
    label: "#c3b371",
    hasBorder: true,
  },
  {
    color: "#885a29",
    label: "#885a29",
    hasBorder: true,
  },
  {
    color: "#991b25",
    label: "#991b25",
    hasBorder: true,
  },
  {
    color: "#ba8b54",
    label: "#ba8b54",
    hasBorder: true,
  },
  {
    color: "#545454",
    label: "#545454",
    hasBorder: true,
  },
  {
    color: "#737373",
    label: "#737373",
    hasBorder: true,
  },
  {
    color: "#5e17eb",
    label: "#5e17eb",
    hasBorder: true,
  },
  {
    color: "#004aad",
    label: "#004aad",
    hasBorder: true,
  },
  {
    color: "#ff1616",
    label: "#ff1616",
    hasBorder: true,
  },
  {
    color: "#ffde59",
    label: "#ffde59",
    hasBorder: true,
  },
  {
    color: "#ffde59",
    label: "#ffde59",
    hasBorder: true,
  },
  {
    color: "#008037",
    label: "#008037",
    hasBorder: true,
  },
  {
    color: "#fbfaf5",
    label: "#fbfaf5",
    hasBorder: true,
  },
  {
    color: "#c9a078",
    label: "#c9a078",
    hasBorder: true,
  },
  {
    color: "#38bbff",
    label: "#38bbff",
    hasBorder: true,
  },
  {
    color: "#5271ff",
    label: "#5271ff",
    hasBorder: true,
  },
  {
    color: "#ff914d",
    label: "#ff914d",
    hasBorder: true,
  },
  {
    color: "#c9e265",
    label: "#c9e265",
    hasBorder: true,
  },
  {
    color: "#7ed957",
    label: "#7ed957",
    hasBorder: true,
  },
  {
    color: "#03989e",
    label: "#03989e",
    hasBorder: true,
  },
  {
    color: "#d9d9d9",
    label: "#d9d9d9",
    hasBorder: true,
  },
];

const tableConfig = {
  contentToolbar: [
    'tableColumn', 'tableRow', 'mergeTableCells',
    'tableProperties', 'tableCellProperties'
  ],

  // Set the palettes for tables.
  tableProperties: {
      borderColors: fontColors,
      backgroundColors: fontColors
  },

  // Set the palettes for table cells.
  tableCellProperties: {
      borderColors: fontColors,
      backgroundColors: fontColors
  }
};

module.exports = { 
  toolbars, 
  fonts, 
  fontSizes, 
  fontColors, 
  tableConfig 
};
